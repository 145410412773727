.header {
  background-image: url('../../assets/header.svg'), var(--shade-grad);
  background-size: cover, cover;
  background-position: center 175%, center center;
  background-repeat: no-repeat, no-repeat;
  padding: 1.5rem 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1500;
}

.headerWrapper {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  display: flex;
  align-items: center
}

.logo img {
  height: 80px
}

.navigation {
  flex: 1;
}

.userName {
  color: var(--white);
  margin-right: 1rem;
  display: flex;
  align-items: center;
}

.navigation,
.navigation nav {
  align-items: center;
  display: flex
}

.navigation a {
  display: block;
  line-height: 1;
  text-decoration: none
}

.pageNavigation {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userNavigation {
  margin-left: 1rem
}

.headerButton.logged {
  background: var(--blue)
}

.avatar {
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid var(--white);
  margin-right: 1rem
}

.avatar img {
  height: 100%;
}

.headerButton {
  display: flex;
  align-items: center;
  color: var(--white);
  border-radius: 6px;
  padding: .5rem 1rem;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0, .2);
}

.signup {
  background: transparent;
  color: var(--white);
  border: 2px solid var(--white)
}

.login {
  margin-left: 1rem;
  background: var(--blue);
  color: var(--white);
}

.navItem {
  padding: 0 1.25rem
}

.navItem:not(:last-of-type) {
  margin-right: 1rem
}

.navLinkText {
  color: var(--white);
  padding: .75rem 0
}

.activeLink {
  background: var(--shade-white)
}

.activeLink .navLinkText {
  border-bottom: 2px solid var(--yellow)
}
