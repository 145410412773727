.footer a {
  color: var(--white)
}

.footerTopbar {
  padding: 1rem 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footerLogo {
  flex: 1;
  color: var(--white);
  margin: 2.5rem 0;
  text-align: center;
}
.footerLogo span {
  display: block;
  margin-top: .75rem;
}

.footerLogo img {
  height: 60px;
  margin-bottom: .75rem;
}

.footerCategories {
  text-align: center;
  flex: 1;
  border-left: 1px solid var(--white);
  border-right: 1px solid var(--white);
}

.footerCategories li:not(:first-of-type) {
  margin-top: 1rem
}

.footerContacts {
  text-align: center;
  flex: 1
}
.footerContacts li:not(:first-of-type) {
  margin-top: 1rem
}

.footerBottombar {
  display: flex;
  justify-content: space-between;
  padding: 1rem 10%;
  color: var(--white);
  background: var(--blue);
}

.footerInfoLinks a {
  color: var(--white)
}

.footerInfoLinks ul {
  display: flex
}

.footerInfoLinks li:not(:last-of-type):after {
  content: '|'
}

.footerInfoLinks li:not(:last-of-type) a {
  margin-right: 1rem
}

.footerInfoLinks li:not(:first-of-type) {
  margin-left: 1rem
}

.footerSocials {
  display: flex
}

.footerSocials span {
  margin-left: 1rem
}
