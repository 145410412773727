:root {
  --cyan:         rgba(54, 169, 225, 1);
  --violet:       rgb(214, 11, 82, 1);
  --shade-cyan:   rgba(54, 169, 225, .8);
  --shade-violet: rgb(214, 11, 82, .8);
  --black:        #000;
  --white:        #FFF;
  --blue:         #008CB8;
  --red:          #E42F41;
  --yellow:       #FFBE00;
  --green:        #2F9E66;
  --dark-gray:    #3C3C3B;
  --light-gray:   #C6C6C6;
  --shade-gray:   rgba(60,60,59, .3);
  --shade-white:  rgba(255,255,255, .3);
  --main-grad:    linear-gradient(to right, var(--cyan) 0%, var(--cyan) 50%, var(--violet) 100%);
  --shade-grad:   linear-gradient(to right, var(--cyan) 0%, var(--shade-cyan) 50%, var(--shade-violet) 100%);
  --header-height: 0px
}

body {
  position: relative;
  font-family: 'Mukta', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Sue Ellen Francisco', cursive;
  margin: 0
}

ul {
  margin: 0;
  padding: 0;
  list-style: none
}

a {
  text-decoration: none
}

main {
  margin-top: var(--header-height);
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  margin-bottom: 100px;
}

.blame {
  position: absolute;
  bottom: 0;
  z-index: -1;
}

.blame2 {
  position: absolute;
  bottom: 0;
  z-index: -1
}

.page-pad {
  padding: 8rem
}

.hero h1 {
  color: var(--white);
  padding-bottom: 1rem;
  text-align: center;
  margin-bottom: 1rem;
  border-bottom: 2px solid var(--yellow)
}
.hero {
  padding: 8rem 0;
  background: url('./assets/team.jpg');
  background-size: cover;
  background-position: center center
}

.hero .strip {
  padding: 4rem;
  background: rgba(60,60,59, .3)
}

.search-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  background: rgba(255,255,255, .3)
}

.video-strip {
  color: var(--white);
  background-image: url('./assets/MainGradient.svg');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
}

.video-strip .left {
  flex: 1;
  padding: 4rem 8rem;
}

.right {
  flex: 1
}

.videoElement {
  border-radius: 4px;
  transform: scale(1.25);
}
