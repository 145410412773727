.loginWrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formWrapper {
  padding: 2rem;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0, .2);
}

.formLogin {
  border: 1px solid var(--light-gray);
  border-radius: 6px;
}

.formLogin h1 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 2rem;
}

.loginSubmit {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .75rem 0;
  background: var(--yellow);
  color: var(--white);
  border-radius: 4px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0, .2);
  cursor: pointer;
  border: none;
  outline: none;
  margin-top: 1rem;
}

.socialLogin {
  display: flex;
  align-items: center;
}

.googleSignin {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .75rem 0;
  background: var(--red);
  color: var(--white);
  border-radius: 4px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0, .2);
  cursor: pointer;
  border: none;
  outline: none;
  margin-bottom: 1rem;
}

.formField {
  padding: 1rem;
  outline: none;
  border: none;
  background: var(--light-gray)
}

.formField:not(:first-of-type) {
  margin-left: 1rem
}


.feeds-wrapper h1 {
  width: auto;
  text-align: center;
  border-bottom: 4px solid var(--yellow);
  margin-bottom: 1rem;
  padding-bottom: 1rem
}

.feeds {
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  max-width: 1080px;
  margin: auto
}

.ad {
  display: flex;
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--yellow)
}

.ad .infos {
  margin-left: 1rem;
}

.ad .infos .price{
  color: var(--red);
  padding: .25rem 0
}

.ad .infos .desctiprion{
  padding: .5rem 0
}

